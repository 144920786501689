import { useQuery } from 'convex/react';
import { memo, useRef, useState } from 'react';
import { Stack } from 'styled-system/jsx';
import { useDeferredValue } from '@ichingio/hooks';
import { api } from '@ichingio/server/api';
import { type ListJournalsItem } from '@ichingio/types';
import { useScrollObserver } from '~/lib/layout';
import { useJournalEditor } from '~/modals/JournalEditor';
import EmptyState from '../components/EmptyState';
import JournalGridItem from '../components/JournalGridItem';
import RowContainer from '../components/RowContainer';
import useEditorRouter from '../hooks/useEditorRouter';
import useGridRows from '../hooks/useGridRows';
import usePageSize from '../hooks/usePageSize';

const JournalsList = memo<{ onNavigate: (to: string) => void }>(
  function JournalsList(props) {
    const { onNavigate } = props;
    const { isOpen: isEditorOpen } = useJournalEditor();
    // Defer un-skipping the query until we guess that the journal editor has closed.
    // Prevents clobbering the rerender during animation as well as appears to be expected UX (value updates obviously after a pause).
    const deferredEditorOpen = useDeferredValue(isEditorOpen, false, 150);

    const { gridColumnCount, initialPage, nextPage } = usePageSize();
    const [loadingCount, updateCount] = useState(() => initialPage);
    const journals = useQuery(
      api.journals.listJournals,
      deferredEditorOpen ? 'skip' : { count: loadingCount },
    );
    const cachedJournals = useRef(journals);

    if (journals) {
      cachedJournals.current = journals;
    }

    const rows = useGridRows<ListJournalsItem>(
      cachedJournals.current,
      gridColumnCount,
    );
    const isLoadingFirstPage =
      journals === undefined && cachedJournals.current === undefined;

    useScrollObserver(() => updateCount((prevState) => prevState + nextPage));

    return (
      <Stack direction="column" gap={[2.5, 4]}>
        {!isLoadingFirstPage && (
          <>
            {rows.length ? (
              <>
                {rows.map((row) => {
                  return (
                    <RowContainer key={row.key} count={gridColumnCount}>
                      {row.items.map((item) => {
                        return (
                          <JournalGridItem
                            key={`${row.key}-${item.id}`}
                            item={item}
                            onNavigate={onNavigate}
                          />
                        );
                      })}
                    </RowContainer>
                  );
                })}
              </>
            ) : (
              <EmptyState
                title="No Journals"
                description="Your list of journals will appear here"
              />
            )}
          </>
        )}
      </Stack>
    );
  },
);

const Journals = () => {
  const onNavigate = useEditorRouter();

  return (
    <>
      <JournalsList onNavigate={onNavigate} />
    </>
  );
};

export default Journals;
